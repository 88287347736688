import React from "react";
//importing Navbar's css file
import "./Navbar.css";
//importing images
import logo from "../Logo-2.png";
import cart from "../shopping-cart.svg";
import profile from "../profile.svg";

import { NavLink as Link } from "react-router-dom";

//Navbar function

//className left - left side of the Navbar
//classname right - right side of the Navbar
const Navbar = () => {
  return (
    <div className="navbar">
      <ul>
        <div className="left">
          <li>
            <Link to="/" data-testid="first">
              <img src={logo} alt="logo" />
            </Link>
          </li>
        </div>
        <div className="right">
          <li>
            <Link to="/">
              <h5>COURSES</h5>
            </Link>
          </li>
          <li>
            <Link to="/wishlist">
              <h5>MYWISHLIST</h5>
            </Link>
          </li>
          <li>
            <Link to="/cart">
              <img src={cart} alt="logo" />
            </Link>
          </li>
          <li>
            <Link to="/profile">
              <img src={profile} alt="logo" />
            </Link>
          </li>
        </div>
      </ul>
    </div>
  );
};

export default Navbar;
