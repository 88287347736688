import React, { useState, useEffect } from "react";
import Modal from "../components/Modal";
import BackDrop from "../components/BackDrop/BackDrop";

import "./CourseDetails.css";

const CourseDetails = (props: any) => {
  const [modalMessage, setModalMessage] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [courseInfo, setCourseInfo] = useState<any>({});

  useEffect(() => {
    let key = window.location.pathname.replace("/courseDetails/", "");
    let selectedCourse = props.cart.filter(
      (course: any) => course.id === parseInt(key)
    )[0];
    // console.log(selectedCourse);
    setCourseInfo(selectedCourse);
  }, []);

  return (
    <>
      {showModal ? (
        <div>
          <BackDrop></BackDrop>
          <Modal
            textInfo={modalMessage}
            closeModal={() => setShowModal(false)}
          />
        </div>
      ) : (
        <>
          {!courseInfo ? (
            <></>
          ) : (
            <>
              <div className="CourseDetails">
                <div className="head">
                  {`All Courses >`} {!courseInfo ? "" : courseInfo.CourseName}
                </div>
                <div className="header">
                  <>
                    <div className="boldHead">{courseInfo.CourseName}</div>
                    <div className="lightHead">{courseInfo.CourseName}</div>
                    <div className="author">{courseInfo.AuthorName}</div>
                    {/* {courseInfo.Tags.map((tag: string) => {
                      return <p className="tag">{tag}</p>;
                    })} */}
                  </>
                </div>
                <div className="body">
                  <h3>Course Details</h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Modi recusandae dolores aspernatur aliquid dolorum obcaecati
                    fugit repellendus laboriosam, doloremque eligendi.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Modi recusandae dolores aspernatur aliquid dolorum obcaecati
                    fugit repellendus laboriosam, doloremque eligendi.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Modi recusandae dolores aspernatur aliquid dolorum obcaecati
                    fugit repellendus laboriosam, doloremque eligendi.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Modi recusandae dolores aspernatur aliquid dolorum obcaecati
                    fugit repellendus laboriosam, doloremque eligendi.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Modi recusandae dolores aspernatur aliquid dolorum obcaecati
                    fugit repellendus laboriosam, doloremque eligendi.
                  </p>
                </div>
                <div className="card">
                  <div className="video"></div>
                  <div className="body">
                    <div className="actualPrice">
                        Rs {courseInfo.discount!==0?courseInfo.price-courseInfo.discount*0.01*courseInfo.price:courseInfo.price} /-
                    </div>
                    <div className="mainPrice">
                        {courseInfo.discount!==0?"Rs "+courseInfo.price+"/-":""}
                    </div>
                    <div className="message">
                      <strong>8 hours </strong>left for the price
                    </div>
                    <div className="actions">
                      <button className="cart">ADD TO CART</button>
                      <button className="wishList">ADD TO WISHLIST</button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default CourseDetails;
