import React from "react";
import "./CartDetails.css";
import { NavLink as Link } from "react-router-dom"; 
const CartDetails = (props: any) => {   //function for adding the prices of the course
  const totalSum = () => {
    if (props.cart.length) {
      let total = 0.0;
      props.cart.map((cart: any) => {
        if (cart.discount) {
          total += cart.price - 0.01 * cart.discount * cart.price;
        } else {
          total += cart.price;
        }
      });
      return "Rs " + total + "/-";
    } else {
      return "";
    }
  };

  //Displaying the course details along with discounted price
  return (
    <div className="CartDetails">
      <h3>YOUR CART DETAILS</h3>
      <div className="cartCardList">
        {props.cart.map((cart: any) => {
          return (
            <div key={cart.id} className="cartCard">
              <p className="name">{cart.CourseName}</p>
              <p className="price">
                Rs{" "}
                {cart.discount !== 0
                  ? cart.price - 0.01 * cart.discount * cart.price
                  : cart.price}{" "}
                /-
              </p>
            </div>
          );
        })}
      </div>
      <div className="CartValue">
        <div>
          <p>Total Cart Value</p>
          <h6>{totalSum()}</h6>
        </div>
        <div>
          <Link to="/cart">Go to Checkout</Link>
        </div>
      </div>
    </div>
  );
};

export default CartDetails;
