import React, { useState } from "react";

import "./WishList.css";
import CartDetails from "../components/CartDetails";
//importing images
import delete_icon from "../delete.svg";

const WishList: React.FC<any> = (props: any) => {
  const [modalMessage, setModalMessage] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <div className="WishList">
      <div className="list">
        <p className="head">My WishList</p>
        {props.wishList.map((wishList: any) => {
          return (
            <div className="Wish" key={wishList.id}>
              <div className="img"></div>
              <div className="information">
                <h3>{wishList.CourseName}</h3>
                {wishList.Tags.map((tag: string) => {
                  return (
                    <p className="tags" key={tag}>
                      {tag}
                    </p>
                  );
                })}
              </div>
              <div className="author">
                <p>{wishList.AuthorName}</p>
              </div>
              <div className="price">
                <p className="main">
                  Rs{" "}
                  {wishList.discount !== 0
                    ? wishList.price - 0.01 * wishList.discount * wishList.price
                    : wishList.price}{" "}
                  /-
                </p>
                <p className="disc">
                  {wishList.discount !== 0
                    ? "Rs " +
                      (wishList.price 
                        ) +
                      "/-"
                    : ""}
                </p>
              </div>
              <div className="addToCart">
                <button
                  onClick={() => {
                    props.addCourseToCart(wishList.id);
                  }}
                >
                  Add to Cart
                </button>
              </div>
              <div className="delete">
                <img
                  src={delete_icon}
                  alt=""
                  onClick={() => props.removeCourseFromWishList(wishList.id)}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="cartDetails">
        <CartDetails cart={props.cart} />
      </div>
    </div>
  );
};

export default WishList;
