//importing libraries
import React, { useState, useEffect } from "react";
//importing Heading css file
import "./Heading.css";

//Heading Function returning the header part
const Heading: React.FC<any> = ({ head }) => {
  return (
    <div className="Heading">
      <p>{head}</p>
    </div>
  );
};

export default Heading;
