import React from "react";
import "./CourseList.css";
import CourseCard from "./CourseCard";

//adding courses to the wishlist 
const CourseList = (props: any) => {
  return (
    <div className="CourseList">
      {props.items.map((item: any) => (
        <CourseCard
          item={item}
          key={item.id}
          addCourseToCart={props.addCourseToCart}
          addCourseToWishList={props.addCourseToWishList}
          inWishList={
            props.wishList.filter((course: any) => course.id === item.id)
              .length !== 0
          }
          inCart={
            props.cart.filter((course: any) => course.id === item.id).length !==
            0
          }
        />
      ))}
    </div>
  );
};

export default CourseList;
