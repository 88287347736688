import React, { useState } from "react";
import { Link } from "react-router-dom";
//importing images
import selected_star_pic from "../star.svg";
import unselected_star_pic from "../unselected.svg";

import Modal from "./Modal";
import BackDrop from "./BackDrop/BackDrop";

//Modal for successsfully added to cart of the course
//Modal for diaplaying item is already in the cart
const CourseCard = ({
  item,
  addCourseToCart,
  inWishList,
  addCourseToWishList,
  inCart,
}: any) => {
  const [modalMessage, setModalMessage] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  return (
    <>
      {showModal ? (
        <div>
          <BackDrop></BackDrop>
          <Modal
            textInfo={modalMessage}
            closeModal={() => setShowModal(false)}
          />
        </div>
      ) : (
        <div className="CourseCard">
          <div className="coname">
            <Link to={`/courseDetails/${item.id}`}>
              <h3>{item.CourseName}</h3>
            </Link>
            {item.Tags.map((tag: string) => {
              return (
                <p className="tags" key={tag}>
                  {tag}
                </p>
              );
            })}
          </div>
          <div className="author">
            <p>{item.AuthorName}</p>
          </div>
          <div className="wish">
            <img
              onClick={() => addCourseToWishList(item.id)}
              src={inWishList ? selected_star_pic : unselected_star_pic}
              alt="wish"
            />
          </div>
          {item.discount ? (
            <>
              <div className="price">
                <p>
                  {item.discount !== 0 ? "Rs " : ""}
                  {item.discount !== 0
                    ? item.price - 0.01 * item.discount * item.price
                    : ""}
                  {item.discount !== 0 ? "/-" : ""}
                </p>
              </div>
              <div className="disc_pric">
                <p>Rs {item.price} /- </p>
              </div>
            </>
          ) : (
            <>
              <div className="price">
                <p>Rs {item.price} /- </p>
              </div>
              <div className="disc_pric">
                <p></p>
              </div>
            </>
          )}
          <div className="add">
            <button
              onClick={() => {
                if (!inCart) {
                  setModalMessage("Course added to the cart successfully");
                  setShowModal(true);
                  setTimeout(() => {
                    addCourseToCart(item.id);
                  }, 1500);
                } else {
                  setModalMessage("Already added to the Cart");
                  setShowModal(true);
                }
              }}
            >
              ADD TO CART
            </button>
          </div>
          <div className="arrow">{/* <img src={arrow} alt="arrow" /> */}</div>
        </div>
      )}
    </>
  );
};

export default CourseCard;
