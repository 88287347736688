import React from "react";

//Search component
const Search: React.FC<any> = ({ setSearch, searchCourse, searchValue }) => {
  return (
    <div className="search">
      <input
        type="text"
        value={searchValue}
        // placeholder={"search"}
        onChange={(e) => setSearch(e.target.value)}
      />
      <button onClick={searchCourse}>Search here</button>
    </div>
  );
};

export default Search;
