import React from "react";

//user profile component
const UserProfile = () => {
  return (
    <div className="UserProfile">
      <div className="up">
        <div className="field">
          <label htmlFor="">Display Name</label>
          <input type="text" />
        </div>
        <div className="field">
          <label htmlFor="">First Name</label>
          <input type="text" />
        </div>
        <div className="field">
          <label htmlFor="">Last Name</label>
          <input type="text" />
        </div>
      </div>
      <div className="field">
        <label htmlFor="">About Yourself</label>
        <textarea name=""></textarea>
      </div>
      <div className="interest">
        <h6>Your Area of Interest</h6>
        <input type="radio" name="Designer" id="Designer" />
        <label htmlFor="Designer">Designer</label>
        <br />
        <input type="radio" name="Developer" id="Developer" />
        <label htmlFor="Developer">Developer</label>
        <br />
        <input type="radio" name="Project Manager" id="Project Manager" />
        <label htmlFor="Project Manager">Project Manager</label>
        <br />
        <input type="radio" name="Sales" id="Sales" />
        <label htmlFor="Sales">Sales</label>
        <br />
      </div>
      <div className="professional">
        <h6>Are you a Student or Professional</h6>
        <input type="radio" name="Student" id="Student" />
        <label htmlFor="Student">Student</label>
        <br />
        <input type="radio" name="professional" id="professional" />
        <label htmlFor="professional">professional</label>
        <br />
      </div>
      <div className="experience">
        <h6>How much experience do you have</h6>
        <input type="radio" name="0-5" id="0-5" />
        <label htmlFor="0-5">0-5</label>
        <input type="radio" name="5-10" id="5-10" />
        <label htmlFor="5-10">5-10</label>
        <input type="radio" name="10 & Above" id="10 & Above" />
        <label htmlFor="10 & Above">10 & Above</label>
      </div>
      <div className="expertise">
        <h6>What's your expertise</h6>
        <input type="radio" name="Java" id="Java" />
        <label htmlFor="Java">Java</label>
        <input type="radio" name="React" id="React" />
        <label htmlFor="React">Backend</label>
        <input type="radio" name="Backend" id="Backend" />
        <label htmlFor="Backend">Backend</label>
      </div>
      <div className="role">
        <h6>Mention your role</h6>
        <textarea name=""></textarea>
      </div>
      <div className="save">
        <button> SAVE </button>
      </div>
    </div>
  );
};

export default UserProfile;
