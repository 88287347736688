import React from "react";
import "./Modal.css";

//Modal Component
const Modal = (props: any) => {
  return (
    <div className="modal">
      <h2>{props.textInfo}</h2>
      <button onClick={props.closeModal}>ok</button>
    </div>
  );
};

export default Modal;
