import React, { useEffect } from "react";
//importing components
import Search from "../components/Search";
import CourseList from "../components/CourseList";
import CartDetails from "../components/CartDetails";
import "./HomePage.css";
//layout of the homepage and with its features
const HomePage: React.FC<any> = ({
  setSearchValue,
  searchCourse,
  searchValue,
  courseItems,
  cart,
  addCourseToCart,
  wishList,
  addCourseToWishList,
  togglePricing,
}) => {
  return (
    <div className="HomePage">
      <div className="list">
        <div className="header">
          <div>
            <h5>All Courses</h5>
          </div>
          <div className="action">
            {/* sorting the price on click of button */}
            <button onClick={togglePricing} id="currentPrice">    
              Current Price
            </button>
          </div>
        </div>
        <CourseList
          items={courseItems}
          addCourseToCart={addCourseToCart}
          wishList={wishList}
          cart={cart}
          addCourseToWishList={addCourseToWishList}
        />
      </div>
      <div className="cartDetails">
        <Search
          setSearch={setSearchValue}
          searchCourse={searchCourse}
          searchValue={searchValue}
        />

        <CartDetails cart={cart} />
      </div>
    </div>
  );
};

export default HomePage;
