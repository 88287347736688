import React, { useState } from "react";

//importing components
import Modal from "../components/Modal";
import BackDrop from "../components/BackDrop/BackDrop";
import "./Cart.css";

//importing images
import delete_icon from "../delete.svg";
import { Link } from "react-router-dom";

const Cart = (props: any) => {
  const [modalMessage, setModalMessage] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const totalAmount = () => {
    let total = 0.0;
    props.cart.map((cart: any) => {
      if (cart.discount) {
        total += cart.price - 0.01 * cart.discount * cart.price;
      } else {
        total += cart.price;
      }
    });
    if (total != 0) {
      return "Rs " + total + "/-";
    } else {
      return "";
    }
  };

  const totalDiscount = () => {
    let totalDisc = 0.0;
    props.cart.map((cart: any) => {
      if (cart.discount) {
        totalDisc += 0.01 * cart.discount * cart.price;
      }
    });
    if (totalDisc != 0) {
      return "You have saved Rs" + totalDisc;
    } else {
      return "";
    }
  };
  return (
    <>
      {showModal ? (
        <div>
          <BackDrop></BackDrop>
          <Modal
            textInfo={modalMessage}
            closeModal={() => setShowModal(false)}
          />
        </div>
      ) : (
        <div className="Cart">
          <div className="list">
            <p className="head">{props.cart.length} products in the cart</p>
            {props.cart.map((cart: any) => {
              return (
                <div className="CartCard" key={cart.id}>
                  <div className="left">
                    <h3>{cart.CourseName}</h3>
                    <p className="author">{cart.AuthorName}</p>
                  </div>
                  <div className="right">
                    <Link to="/wishlist">
                      <p className="wish">Move to WishList</p>
                    </Link>
                    <p className="amount">
                      Rs
                      {cart.discount !== 0
                        ? cart.price - 0.01 * cart.discount * cart.price
                        : cart.price}
                    </p>

                    <p
                      className="delete"
                      onClick={() => props.removeCourseFromCart(cart.id)}
                    >
                      <img src={delete_icon} alt="" />
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="price">
            <p className="head">Total Amount</p>
            <p className="total">{totalAmount()}</p>
            <p className="disc">{totalDiscount()}</p>
            <button
              onClick={() => {
                setModalMessage("You have successfully placed your order");
                setShowModal(true);
              }}
            >
              CheckOut
            </button>
          </div>
          {/* <CartDetails cart={props.cart} /> */}
        </div>
      )}
    </>
  );
};

export default Cart;
