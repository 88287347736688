import React from "react";
import { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";

//Components
import Navbar from "./components/Navbar";
import Heading from "./components/Heading";
import Search from "./components/Search";
//Pages
import HomePage from "./pages/HomePage";
import Cart from "./pages/Cart";
import UserProfile from "./pages/UserProfile";
import WishList from "./pages/WishList";
import CourseDetails from "./pages/CourseDetails";
//imorting Course component
import Courses from "./components/CourseList";
import Heading_cart from "./components/Heading_cart";

function App() {
  //storing the data in item hook
  const [courseItems, setItems] = useState<any>([
    {
      id: 1,
      CourseName: "Resposive React",
      AuthorName: "Joseph Mane",
      Tags: ["React"],
      price: 600,
      discount: 10,
    },
    {
      id: 2,
      CourseName: "Java",
      AuthorName: "Sadio Mane",
      Tags: ["React"],
      price: 700,
      discount: 7.5,
    },
    {
      id: 3,
      CourseName: "Machine Learning",
      AuthorName: "Java",
      Tags: ["React", "React Hooks"],
      price: 1000,
      discount: 0,
    },
    {
      id: 4,
      CourseName: "HTML",
      AuthorName: "Shailesh",
      Tags: ["React", "React Hooks"],
      price: 500,
      discount: 15,
    },
  ]);
  const [heading, setHeading] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [pricing, setPricing] = useState<boolean>(true);
  const [searchResults, setSearchResults] = useState<any>([]);
  const [wishList, setWishList] = useState<any>([]);
  const [cart, setCart] = useState<any>([]);

  //function for searching with coursename authorname and tags
  const searchCourse = () => {
    let results = courseItems.filter((course: any) => {
      return (
        course.CourseName.includes(searchValue) ||
        course.AuthorName.includes(searchValue) ||
        course.Tags.includes(searchValue)
      );
    });

    setSearchResults(results);
  };

  //function to add courses in course details cart
  const addCourseToCart = (id: number) => {
    let currentCartList = [...cart];
    if (
      currentCartList.filter((course: any) => course.id === id).length === 0
    ) {
      currentCartList.push(
        courseItems.filter((course: any) => course.id === id)[0]
      );
    }
    setCart(currentCartList);
  };

  //function for removing course from cart with delete icon
  const removeCourseFromCart = (id: number) => {
    let currentCartList = [...cart];
    currentCartList = currentCartList.filter((course: any) => course.id !== id);
    setCart(currentCartList);
  };

  //function to remove courses from the wish list
  const removeCourseFromWishList = (id: number) => {
    let currentWishList = [...wishList];
    currentWishList = currentWishList.filter((course: any) => course.id !== id);
    setWishList(currentWishList);
  };

  //function to add courses to the wishlist
  const addCourseToWishList = (id: number) => {
    let currentWishList = [...wishList];
    if (
      currentWishList.filter((course: any) => course.id === id).length === 0
    ) {
      currentWishList.push(
        courseItems.filter((course: any) => course.id === id)[0]
      );
    } else {
      currentWishList = currentWishList.filter((course: any) => {
        return course.id !== id;
      });
    }
    setWishList(currentWishList);
  };

  //function to sort the courses according to the price
  const togglePricing = () => {
    let currentCourses = [...courseItems];
    if (pricing) {
      currentCourses = currentCourses.sort((a: any, b: any) => {
        return a.price - b.price;
      });
    } else {
      currentCourses = currentCourses.sort((a: any, b: any) => {
        return b.price - a.price;
      });
    }
    setPricing(!pricing);
    setItems(currentCourses);
  };
  // const location = useLocation();

  // useEffect(() => {
  //   // let path = window.location.pathname;
  //   // if (path === "/") {
  //   //   setHeading("Discover Latest Courses on React");
  //   // } else if (path === "/cart") {
  //   //   setHeading("Shopping Cart");
  //   // }
  //   console.log("location changed");
  //   console.log(location);
  // }, [location]);

  return (
    // adding router
    <Router>
      <div className="App">
        <Navbar />
        <Heading head={heading} />
        <Switch>
          <Route
            path="/"
            exact
            component={() => (
              <HomePage
                togglePricing={togglePricing}
                courseItems={searchResults.length ? searchResults : courseItems}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                searchCourse={searchCourse}
                cart={cart}
                addCourseToCart={addCourseToCart}
                wishList={wishList}
                addCourseToWishList={addCourseToWishList}
              />
            )}
          />
          <Route
            path="/cart"
            exact
            component={() => (
              <Cart cart={cart} removeCourseFromCart={removeCourseFromCart} />
            )}
          />
          <Route path="/profile" exact component={UserProfile} />
          <Route
            path="/wishlist"
            exact
            component={() => (
              <WishList
                cart={cart}
                wishList={wishList}
                addCourseToCart={addCourseToCart}
                removeCourseFromWishList={removeCourseFromWishList}
              />
            )}
          />
          <Route
            path="/courseDetails/:id"
            exact
            component={() => <CourseDetails cart={cart} />}
          />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
// /* <Search handleChange={filterdata()} />
/* <Search handleChange={(e: any) => setSearchItem(filterdata)} /> */
{
  /* <Search items={item} onSearch={filterdata} /> */
}
{
  /*  */
}
{
  /* <Courses items={item} /> */
}
// <Heading />
{
  /* <div className="App"> */
}
{
  /* <Navbar /> */
}
// const [searchitem, setSearchItem] = useState<any>("");

// const filterdata = () => {
//   return item.filter(
//     item.CourseName.toLowerCase().includes(item.CourseName) ||
//       item.AuthorName.toLowerCase().includes(item.AuthorName) ||
//       item.Tag.toLowerCase().includes(item.Tag)
//   );
// };

//returning the components
